<template>
  <section id="clients" class="clients section light-background">
    <div class="container" data-aos="zoom-in">
      <div class="swiper init-swiper">
        <script type="application/json" class="swiper-config">
          {
            "loop": true,
            "speed": 600,
            "autoplay": {
              "delay": 5000
            },
            "slidesPerView": "auto",
            "pagination": {
              "el": ".swiper-pagination",
              "type": "bullets",
              "clickable": true
            },
            "breakpoints": {
              "320": {
                "slidesPerView": 2,
                "spaceBetween": 40
              },
              "480": {
                "slidesPerView": 3,
                "spaceBetween": 60
              },
              "640": {
                "slidesPerView": 4,
                "spaceBetween": 80
              },
              "992": {
                "slidesPerView": 5,
                "spaceBetween": 120
              },
              "1200": {
                "slidesPerView": 6,
                "spaceBetween": 120
              }
            }
          }
        </script>
        <div class="swiper-wrapper align-items-center technologie-section">
          <div class="swiper-slide" v-for="lang in languages" :key="lang.name">
            <img :src="lang.img" class="img-fluid" :alt="lang.name" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import languages from '../data/languages.json';
export default {
  name: "Language",
  data() {
    return {
      languages: languages,
    };
  },
};
</script>

<style scoped>
.technologie-section .img-fluid {
  max-height: 45px !important;
}
</style>
