<template>
  <div>
    <div class="g-recaptcha" :data-sitekey="siteKey"></div>
  </div>
</template>

<script>
export default {
  props: {
    siteKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    if (typeof window.grecaptcha === "undefined") {
    //   const script = document.createElement("script");
    //   script.src = `https://www.google.com/recaptcha/api.js?onload=onLoadReCaptcha&render=explicit`;
    //   script.async = true;
    //   script.defer = true;
    //   script.onload = () => {
    //     this.isLoaded = true;
    //   };
    //   document.body.appendChild(script);
    } else {
      this.isLoaded = true;
    }
  },
};
</script>

<style scoped></style>
