import Vue from 'vue';
import Router from 'vue-router';
import Home from './screens/Home.vue'
import LegalNotices from './screens/LegalNotices.vue'

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/legal-notices',
        name: 'legal',
        component: LegalNotices,
    },
];

const router = new Router({
    mode: 'history',
    routes,
});

export default router;