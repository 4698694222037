<template>
  <section id="services" class="services section light-background">
    <div class="container section-title" data-aos="fade-up">
      <h2>Nos services</h2>
      <p></p>
    </div>

    <div class="container">
      <div class="row gy-4">
        <div
          v-for="(service, index) in services"
          :key="index"
          class="col-xl-3 col-md-6 d-flex"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="service-item position-relative">
            <div class="icon"><i :class="service.icon"></i></div>
            <h4>
              <a href="" class="stretched-link">{{ service.title }}</a>
            </h4>
            <p>
              {{ service.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import services from "../data/services.json";
export default {
  name: "Service",
  data() {
    return {
      services: services,
    };
  },
};
</script>
