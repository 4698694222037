<template>
  <section id="team" class="team section">
    <div class="container section-title" data-aos="fade-up">
      <h2>Équipe</h2>
      <p>
        Notre équipe, composée de développeurs, de designers et de chefs de projet
        expérimentés, garantit une réalisation professionnelle de vos projets
      </p>
    </div>

    <div class="container">
      <div class="row gy-4">
        <div
          v-for="(item, index) in equipe"
          :key="index"
          class="col-lg-6"
          data-aos="fade-up"
          :data-aos-delay="100 * (index + 1)"
        >
          <div class="team-member d-flex align-items-start">
            <div class="pic">
              <img :src="item.img" class="img-fluid" alt="" />
            </div>
            <div class="member-info">
              <h4>{{ item.name }}</h4>
              <span>{{ item.activity }}</span>
              <p>{{ item.description }}</p>
              <div class="social">
                <a
                  target="_blank"
                  :href="item.socials.twitter"
                  v-if="item.socials.twitter"
                >
                  <i class="bi bi-twitter-x"></i>
                </a>
                <a
                  target="_blank"
                  :href="item.socials.facebook"
                  v-if="item.socials.facebook"
                >
                  <i class="bi bi-facebook"></i>
                </a>
                <a
                  target="_blank"
                  :href="item.socials.instagram"
                  v-if="item.socials.instagram"
                >
                  <i class="bi bi-instagram"></i>
                </a>
                <a
                  target="_blank"
                  :href="item.socials.linkedin"
                  v-if="item.socials.linkedin"
                >
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import equipe from "../data/equipe.json";
export default {
  name: "Team",
  props: {},
  data() {
    return {
      equipe: equipe,
    };
  },
};
</script>

<style scoped></style>
