<template>
  <div>
    <Header />
    <main class="main">
      <section class="content-body">
        <section id="faq-2" class="faq-2 section w-100 mt-5">
          <div class="container section-title mt-5" data-aos="fade-up">
            <h2>Mentions Légales</h2>
            <p>{{ notices.message ? notices.message : "" }}</p>
          </div>

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="faq-container">
                  <div
                    v-for="(notice, index) in notices.questions"
                    :key="index"
                    :class="`faq-item light-background-notice faq-active`"
                    data-aos="fade-up"
                    :data-aos-delay="200 * (index ? index : 1)"
                  >
                    <h3>{{ notice.question }}</h3>
                    <div class="faq-content">
                      <p v-html="notice.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import notices from "../data/notices";
export default {
  name: "LegalNotices",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      notices: notices,
    };
  },
};
</script>
<style scoped>
.light-background-notice {
  /* background-color: #f5f6f8 !important; */
}
</style>
