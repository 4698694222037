<template>
  <main class="main">
    <section id="hero" class="hero section dark-background">
      <div class="container">
        <div class="row gy-4">
          <div
            class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center"
            data-aos="zoom-out"
          >
            <h1>De meilleures solutions pour votre entreprise</h1>
            <p>
              pour vos besoins en développement logiciel et application web, ensemble,
              construisons un avenir numérique performant et innovant.
            </p>
            <div class="d-flex">
              <!-- <a href="#about" class="btn-get-started">Get Started</a>
              <a
                href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                class="glightbox btn-watch-video d-flex align-items-center"
                ><i class="bi bi-play-circle"></i><span>Watch Video</span></a
              > -->
            </div>
          </div>
          <div
            class="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src="assets/img/hero-img.png" class="img-fluid animated" alt="" />
          </div>
        </div>
      </div>
    </section>

    <Language />

    <section id="about" class="about section">
      <div class="container section-title" data-aos="fade-up">
        <h2>À propos de nous</h2>
      </div>

      <div class="container">
        <div class="row gy-4">
          <div
            class="col-lg-6 content"
            data-aos="fade-up"
            data-aos-delay="100"
            style="text-align: justify"
          >
            <p>
              Algérie Devops est une société dynamique et innovante. Fondée en 2024 pour
              offrir un cadre légal au projet
              <a href="https://hakim-dz.com" target="_blank">Hakim-DZ</a> qui est une
              plateforme médicale intégrant le dossier médical informatisé (DMI) initiée
              en 2022 et pour lequel nous avons obtenu le label projet innovant. Forts de
              notre expérience en développement pour de grandes entreprises Françaises,
              nous avons décidé de mettre nos compétences au service de notre pays.
            </p>
          </div>

          <div
            class="col-lg-6"
            data-aos="fade-up"
            data-aos-delay="200"
            style="text-align: justify"
          >
            <p>
              Notre entreprise est spécialisée dans le développement de logiciels et
              d'applications web sur mesure. Notre mission est d'accompagner les
              entreprises dans leur transformation numérique en leur fournissant des
              solutions technologiques adaptées à leurs besoins spécifiques.
            </p>
            <!-- <a href="#" class="read-more"
              ><span>Read More</span><i class="bi bi-arrow-right"></i
            ></a> -->
          </div>
        </div>

        <div class="row gy-4">
          <div
            class="col-lg-6 content"
            data-aos="fade-up"
            data-aos-delay="100"
            style="text-align: justify"
          >
            <p style="text-align: right">
              <img
                src="assets/img/LabelStartup-removebg-preview.png"
                title="label startup"
                width="190px"
              />
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <img
                src="assets/img/LabelProjetInnovant.png"
                title="label startup"
                width="190px"
              />
            </p>
          </div>

          <div
            class="col-lg-6"
            data-aos="fade-up"
            data-aos-delay="200"
            style="text-align: justify"
          >
            <p>
              SPAS Algérie Devops a obtenu le label Startup délivré par le ministère de
              l’économie de la connaissance et des startups et a également obtenu le label
              Projet innovant pour sa plateforme médicale
              <a href="https://hakim-dz.com" target="_blank">Hakim-DZ</a>.
            </p>
            <!-- <a href="#" class="read-more"
              ><span>Read More</span><i class="bi bi-arrow-right"></i
            ></a> -->
          </div>
        </div>
      </div>
    </section>

    <section id="why-us" class="section why-us light-background" data-builder="section">
      <div class="container-fluid">
        <div class="row gy-4">
          <div
            class="col-lg-7 d-flex flex-column justify-content-center order-2 order-lg-1"
          >
            <div class="content px-xl-5" data-aos="fade-up" data-aos-delay="100">
              <h3><span>Pourquoi choisir </span><strong>Algérie Devops ?</strong></h3>
              <p>
                Notre équipe, composée de développeurs, de designers et de chefs de projet
                expérimentés, garantit une réalisation professionnelle de vos projets,
                avec une attention particulière portée à l'expérience utilisateur et à la
                performance.
              </p>
            </div>

            <div class="faq-container px-xl-5" data-aos="fade-up" data-aos-delay="200">
              <div class="faq-item faq-active">
                <h3>
                  <span
                    ><i class="bi bi-check2-circle"></i>&nbsp; <span>Innovation </span>
                  </span>
                </h3>
                <div class="faq-content">
                  <p>
                    <span
                      >Nous croyons en l'importance de l'innovation continue et restons à
                      la pointe des dernières technologies.</span
                    >
                  </p>
                </div>
                <i class="faq-toggle bi bi-chevron-right"></i>
              </div>

              <div class="faq-item">
                <h3>
                  <span> <i class="bi bi-check2-circle"></i>&nbsp;Qualité </span>
                </h3>
                <div class="faq-content">
                  <p>
                    <span>
                      Nous nous engageons à fournir des produits de haute qualité qui
                      répondent aux normes les plus strictes.</span
                    >
                  </p>
                </div>
                <i class="faq-toggle bi bi-chevron-right"></i>
              </div>

              <div class="faq-item">
                <h3>
                  <span> <i class="bi bi-check2-circle"></i>&nbsp;Collaboration </span>
                </h3>
                <div class="faq-content">
                  <p>
                    <span>
                      Nous privilégions une approche collaborative avec nos clients, en
                      impliquant toutes les parties prenantes à chaque étape du
                      processus.</span
                    >
                  </p>
                </div>
                <i class="faq-toggle bi bi-chevron-right"></i>
              </div>

              <div class="faq-item">
                <h3>
                  <span>
                    <i class="bi bi-check2-circle"></i>&nbsp;Satisfaction client
                  </span>
                </h3>
                <div class="faq-content">
                  <p>
                    <span>
                      La satisfaction de nos clients est au cœur de nos préoccupations.
                      Nous travaillons sans relâche pour dépasser leurs attentes.</span
                    >
                  </p>
                </div>
                <i class="faq-toggle bi bi-chevron-right"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-5 order-1 order-lg-2 why-us-img">
            <img
              src="assets/img/why-us.png"
              class="img-fluid"
              alt=""
              data-aos="zoom-in"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="skills" class="skills section">
      <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="row">
          <div class="col-lg-6 d-flex align-items-center">
            <img src="assets/img/skills.png" class="img-fluid" alt="" />
          </div>

          <div class="col-lg-6 pt-4 pt-lg-0 content">
            <h3>Notre maitrise des technologies & outils</h3>
            <p class="fst-italic">
              les langages de programmation et outils de développement que nous utilisons
              au quotidien
            </p>

            <div class="skills-content skills-animation">
              <div class="progress">
                <span class="skill"><span>PHP</span> <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill"><span>HTML/CSS</span> <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill"><span>JS</span> <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill"><span>PYTHON</span> <i class="val">85%</i></span>
                <div class="progress-bar-wrap">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="85"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill"><span>C#</span> <i class="val">95%</i></span>
                <div class="progress-bar-wrap">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="95"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <Service />

    <Our-work />

    <Team />

    <Contact />
  </main>
</template>

<script>
import Contact from "./Contact.vue";
import Team from "./Team.vue";
import Service from "./Service.vue";
import Language from "./Language.vue";
import OurWork from "./OurWork.vue";
export default {
  name: "Body",
  props: {},
  components: {
    Contact,
    Team,
    Language,
    Service,
    OurWork,
  },
};
</script>

<style scoped>
@import "../app.scss";
</style>
