<template>
  <div>
    <header id="header" class="header d-flex align-items-center fixed-top">
      <div
        class="container-fluid container-xl position-relative d-flex align-items-center"
      >
        <a href="/" class="logo d-flex align-items-center me-auto">
          <h1 class="sitename"><img src="assets/img/213devops.png" /></h1>
        </a>

        <nav id="navmenu" class="navmenu">
          <ul>
            <li><a href="/#hero">Accueil</a></li>
            <!-- <li>
              <router-link :to="{ path: '/', hash: '#about' }">À propos</router-link>
            </li> -->
            <li><a href="/#about">À propos</a></li>
            <li><a href="/#services">Services</a></li>
            <li><a href="/#portfolio">Portfolio</a></li>
            <li><a href="/#contact">Contact</a></li>
          </ul>
          <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
};
</script>

<style scoped></style>
