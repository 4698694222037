import Vue from 'vue';
import Axios from 'axios'
import App from './App.vue';
import router from './router.js';

Vue.config.productionTip = false
Vue.prototype.$http = Axios;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

window.onLoadReCaptcha = function () {
};


require('../public/assets/js/main.js');
