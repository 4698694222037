<template>
  <section id="contact" class="contact section">
    <div class="container section-title" data-aos="fade-up">
      <h2>Contact</h2>
      <p></p>
    </div>

    <div class="container" data-aos="fade-up" data-aos-delay="100">
      <div class="row gy-4">
        <div class="col-lg-5">
          <div class="info-wrap">
            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
              <i class="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h3>Adresse</h3>
                <p>Résidence Mirabel - Bloc C5, étage 1 Bir El Djir - Oran</p>
              </div>
            </div>

            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
              <i class="bi bi-telephone flex-shrink-0"></i>
              <div>
                <h3>Appelez-nous</h3>
                <p>( +33) 6 31 61 74 12</p>
                <p>(+213) 7 74 42 12 06</p>
                <p>(+213) 7 71 93 16 23</p>
              </div>
            </div>

            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
              <i class="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h3>Envoyez-nous un e-mail</h3>
                <p>contact@213devops.com</p>
              </div>
            </div>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202.47269239782784!2d-0.5739803799853722!3d35.71236809053825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd7e63c8c2a76b65%3A0xf52bab82a525f616!2sR%C3%A9sidence%20MiraBelle!5e0!3m2!1sen!2sdz!4v1729582893847!5m2!1sen!2sdz"
              frameborder="0"
              style="border: 0; width: 100%; height: 270px"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div class="col-lg-7">
          <form class="php-email-form" data-aos="fade-up" data-aos-delay="200">
            <div class="row gy-4" v-if="!success">
              <div class="col-md-6">
                <label for="name-field" class="pb-2">Votre nom</label>
                <input
                  type="text"
                  v-model="name"
                  name="name"
                  id="name-field"
                  class="form-control"
                  required=""
                />
                <span class="msg-error" v-if="error.name">{{ error.name }}</span>
              </div>

              <div class="col-md-6">
                <label for="email-field" class="pb-2">Votre email</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  name="email"
                  id="email-field"
                  required=""
                />
                <span class="msg-error" v-if="error.email">{{ error.email }}</span>
              </div>

              <div class="col-md-12">
                <label for="subject-field" class="pb-2">Sujet</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="subject"
                  name="subject"
                  id="subject-field"
                  required=""
                />
                <span class="msg-error" v-if="error.subject">{{ error.subject }}</span>
              </div>

              <div class="col-md-12">
                <label for="message-field" class="pb-2">Message</label>
                <textarea
                  class="form-control"
                  v-model="message"
                  name="message"
                  rows="10"
                  id="message-field"
                  required=""
                ></textarea>
                <span class="msg-error" v-if="error.message">{{ error.message }}</span>
              </div>

              <Recaptcha :siteKey="siteKey" ref="recaptcha" />
              <span class="msg-error" v-if="error.recaptcha">{{ error.recaptcha }}</span>

              <div class="col-md-12 text-center">
                <button type="button" v-if="!loading" @click="sendMessage">
                  Envoyer
                </button>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <div class="loading" v-if="loading">Loading ...</div>
              <div class="error-message" v-if="server">{{ server }}</div>
              <div class="sent-message" v-if="success">
                Votre message a été envoyé. Merci !
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Recaptcha from "../plugins/Recaptcha.vue";

export default {
  components: { Recaptcha },
  name: "Contact",
  props: {},
  data() {
    return {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loading: false,
      success: false,
      name: "",
      email: "",
      subject: "",
      message: "",
      error: { name: "", email: "", subject: "", message: "", recaptcha: "" },
      server: "",
    };
  },
  methods: {
    sendMessage: async function () {
      this.error.recaptcha = "";
      this.error.name = !this.name ? "Vous devez entrer le nom" : "";
      this.error.email = !this.email ? "Vous devez entrer E-mail" : "";
      this.error.subject = !this.subject ? "Vous devez entrer le sujet" : "";
      this.error.message = !this.message ? "Vous devez ecrire un message" : "";

      this.server = "";
      if (
        this.error.name ||
        this.error.email ||
        this.error.subject ||
        this.error.message
      ) {
        return;
      }

      try {
        if (typeof window.grecaptcha !== "undefined") {
          const response = window.grecaptcha.getResponse();
          if (response.length === 0) {
            this.error.recaptcha = "Veuillez compléter le reCAPTCHA";
            return;
          }

          this.$http
            .post(`${process.env.VUE_APP_HSERVER_URL}/api/recaptcha`, {
              recaptcha: response,
            })
            .then((res) => {
              console.log("Success:", res.data);
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          this.error.recaptcha =
            "reCAPTCHA n'est pas encore chargé. Veuillez réessayer plus tard.";
          return;
        }
      } catch (error) {
        this.error.recaptcha =
          "reCAPTCHA n'est pas encore chargé. Veuillez réessayer plus tard.";
        return;
      }

      var data = {
        emai: this.email,
        name: this.name,
      };

      var _body = `<br> Email: ${this.email} <br> Nom: ${
        this.name
      } <br><br>Mesage: ${this.message.replace(
        /{{(\w+)}}/g,
        (_, key) => data[key] || ""
      )} `;

      this.loading = true;

      try {
        const res = await fetch(`${process.env.VUE_APP_HSERVER_URL}/send_email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: process.env.VUE_APP_EMAIL_TO,
            subject: this.subject,
            html: _body,
            replyTo: this.email,
          }),
        });
        var _response = await res.text();
        const statut = _response.substring(0, _response.indexOf(":"));

        if (statut == "Error") {
          this.server = "L'envoi du courrier a échoué, réessayez plus tard";
        } else {
          this.success = true;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.server = "L'envoi du courrier a échoué, réessayez plus tard";
      }
    },
  },
};
</script>

<style scoped>
.msg-error {
  color: red;
  font-size: 12.5px;
}
</style>
