<template>
  <section id="portfolio" class="portfolio section">
    <div class="container section-title" data-aos="fade-up">
      <h2>Ils nous font confiance</h2>
      <p></p>
    </div>

    <div class="container">
      <div
        class="isotope-layout"
        data-default-filter="*"
        data-layout="masonry"
        data-sort="original-order"
      >
        <div class="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">
          <div
          v-for="(item, index) in list" :key="index"
           class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img :src="item.img" class="img-fluid" :alt="item.name" />
            <div class="portfolio-info">
              <h4>{{ item.name }}</h4>
              <p></p>
              <a
                :href="item.img"
                :title="item.name"
                data-gallery="portfolio-gallery-app"
                class="glightbox preview-link"
                ><i class="bi bi-zoom-in"></i
              ></a>
              <!-- <a href="#portfolio" title="More Details" class="details-link"
                  ><i class="bi bi-link-45deg"></i
                ></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../data/work_with';
export default {
    name: 'OurWork',
    data() {
        return {
            list: data
        }
    },
}
</script>