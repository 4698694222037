<template>
  <footer id="footer" class="footer">
    <!-- <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>
              Subscribe to our newsletter and receive the latest news about our products
              and services!
            </p>
            <form action="forms/newsletter.php" method="post" class="php-email-form">
              <div class="newsletter-form">
                <input type="email" name="email" /><input
                  type="submit"
                  value="Subscribe"
                />
              </div>
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your subscription request has been sent. Thank you!
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->

    <div class="footer-newsletter">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-3 col-md-6 footer-about">
            <a href="/" class="d-flex align-items-center">
              <span class="sitename">213Devops</span>
            </a>
            <div class="footer-contact pt-3">
              <p>Résidence Mirabel - Bloc C5, étage 1.</p>
              <p>Bir El Djir - Oran</p>
              <p class="mt-3">
                <strong>Téléphone:</strong>&nbsp;&nbsp;&nbsp;
                <span>&nbsp;&nbsp;(+33) 6 31 61 74 12</span> <br />
                <span
                  >&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                  &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; (+213) 7 74 42 12 06</span
                >
                <br />
                <span
                  >&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                  &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; (+213) 7 93 02 42 11</span
                >
              </p>
              <p>
                <strong>Email:</strong> &nbsp;&nbsp;<span>contact@213devops.com</span>
              </p>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Liens utiles</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="/">Accueil</a></li>
              <li>
                <i class="bi bi-chevron-right"></i> <a href="/#about">À propos de nous</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i> <a href="/#services">Services</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="/legal-notices">Mentions légales</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-3 footer-links">
            <h4>Nos services</h4>
            <ul>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="/#services">Développement d'applications web</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="/#services">Développement de logiciels sur mesure</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="/#services">Consultation en transformation numérique</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="/#services">Intégration et migration de systèmes</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="/#services">Support et maintenance</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12">
            <h4 style="font-size: 16px">Découvrez notre plateforme médicale</h4>
            <br />
            <div class="social-links d-flex our_plat">
              <a href="https://hakim-dz.com" target="_blank">
                <img src="assets/img/hakim-dz.png" title="Hakim-DZ" height="85px" />
              </a>

              <!-- <a href=""><i class="bi bi-twitter-x"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a> -->
            </div>
            <div class="social-links d-flex our_plat mt-4">
              <a href="https://hakim-dz.com" target="_blank" style="display: contents;color:teal">hakim-dz.com</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span> <strong class="px-1 sitename">213devops</strong>
          <span>All Rights Reserved</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<style scoped>
.social-links a {
  border: 0 !important;
}
.our_plat {
  display: flex !important;
  justify-content: center !important;
}
</style>
